<template>
  <div class="disableBtn">
    <el-popover placement="top" width="180" v-model="visible">
      <div class="contant">
        <p class="disableBtnContent">
          <i class="iconfont icon-warning"></i>
          {{`确认${data.isDisabled ===1?'禁用':'启用'}吗？`}}</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" @click="visible = false">取消</el-button>
          <el-button type="primary" size="mini" @click="handelClick">确定</el-button>
        </div>
      </div>
      <ColorTextBtn slot="reference" :type="type">{{ text }}</ColorTextBtn>
    </el-popover>
  </div>
</template>

<script>
import templateAPi from '@/api/productTemplate/index'
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: {}
    },
    sup_this: {
      type: Object,
      required: true
    },
    categoryId: Number | String,
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    type() {
      if (this.data.isDisabled) {
        return 'danger'
      } else {
        return 'primary'
      }
    },
    text() {
      if (this.data.isDisabled) {
        return '禁用'
      } else {
        return '启用'
      }
    }
  },
  methods: {
    async handelClick() {
      if (this.data.isDisabled) {
        console.log('this.data.isDisabled',this.data.isDisabled);
        const { code } = await templateAPi.disable({
          id: this.data.id,
          isDisabled: 0,
          categoryId:this.categoryId
        })
        if (code == 0) {
          this.$message.success('操作成功')
          this.visible = false
        } else {
          this.$message.error('操作失败')
          this.visible = false
        }
      } else {
        const { code } = await templateAPi.disable({
          id: this.data.id,
          isDisabled: 1,
          categoryId:this.categoryId
        })
        if (code == 0) {
          this.$message.success('操作成功')
          this.visible = false
        } else {
          this.$message.error('操作失败')
          this.visible = false
        }
      }
      this.sup_this.init()
    }
  }
}
</script>

<style scoped lang="scss">
.disableBtn {
  display: inline-block;
}
</style>
<style lang="scss">
.disableBtnContent{
  font-size: 12px;
  margin-bottom: 10px;
  .iconfont{
    font-size: 12px;
    color:#ff9900
  }
}
</style>
